import { Injectable } from '@angular/core';
import { CommonService } from '@core/services/common.service';
import { Observable, switchMap } from 'rxjs';
import { CreateTemplatePayload, TemplateDataModel, TemplatesModel } from './';
import { HttpClient } from '@angular/common/http';
import { ClientsService } from '../clients';
import { ULIDType } from 'jplus-design-system-angular';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends CommonService {
  constructor(
    protected override _http: HttpClient,
    private _clientService: ClientsService
  ) {
    super(_http);
  }

  getClientTemplates(queryParam = ''): Observable<TemplatesModel[]> {
    return this._clientService.getDataByClientId<TemplateDataModel, TemplatesModel>(
      `${this._apiUrl}/clients/{id}/templates?q=${queryParam}`
    );
  }

  getClientTemplateById(templateId: ULIDType): Observable<TemplatesModel> {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.get<TemplatesModel>(`${this._apiUrl}/clients/${client.id}/templates/${templateId}`))
    );
  }

  createTemplate(payload: CreateTemplatePayload): Observable<TemplateDataModel> {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.post<TemplateDataModel>(`${this._apiUrl}/clients/${client.id}/templates`, payload))
    );
  }

  updateTemplate(templateId: ULIDType, payload: CreateTemplatePayload) {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.put<TemplateDataModel>(`${this._apiUrl}/clients/${client.id}/templates/${templateId}`, payload))
    );
  }

  deleteTemplate(templateId: ULIDType): Observable<null> {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.delete<null>(`${this._apiUrl}/clients/${client.id}/templates/${templateId}`))
    );
  }
}
