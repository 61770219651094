export const STORAGE_KEYS = {
  URL: 'returnUrl',
  SLUG: 'slug'
} as const;

export function setReturnUrl(url: string) {
  localStorage.setItem(STORAGE_KEYS.URL, url);
}

export function getReturnUrl(): string {
  return localStorage.getItem(STORAGE_KEYS.URL) || '';
}

export function setClientSlug(slug: string) {
  localStorage.setItem(STORAGE_KEYS.SLUG, slug);
}

export function getClientSlug(): string {
  return localStorage.getItem(STORAGE_KEYS.SLUG) || '';
}
