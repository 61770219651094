import { AuthConfig } from '@auth0/auth0-angular/lib/auth.config';

export const environment = {
  production: false,
  apiBaseUrl: 'https://api.content.dev.jplus.io',
  pusher: {
    key: '6cde79ce6f0969f6551e',
    cluster: 'eu'
  },
  authConfig: {
    domain: 'jellyfish-dev.eu.auth0.com',
    clientId: 'Z9KxgnFJB4SWxrfCK7rzPiM7zsZg8EeD',
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    audience: '',
    scope: 'openid profile email',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/auth-callback`
    },
    errorPath: '/access-denied'
  } as AuthConfig,
  sentry: {
    dsn: 'https://96d6a9ca77cb092dd1aa5805667358db@o452613.ingest.sentry.io/4506185533882368'
  },
  mixpanel: {
    token: '603feb3175de7fbe5c2ef4cca341f1bf'
  }
};
