import { Directive, inject } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ULIDType } from 'jplus-design-system-angular';

import { ClientsService, PusherService } from '@core/services';

@Directive()
export abstract class PusherjsAbstract {
  channelName = (id: ULIDType): string => `client.${id}.batches`;
  clientService: ClientsService = inject(ClientsService);

  constructor(public pusherService: PusherService) {}

  pusherSub() {
    this.clientService.currentClient
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged((previous, current) => previous.id === current.id),
        debounceTime(500),
        map(client => {
          if (client.id) {
            if (this.pusherService.channel.subscribed) this.pusherUnSub();

            this.pusherService.channel = this.pusherService.webSocketService.pusher.subscribe(this.channelName(client.id));
            this.pusherService.pusherBind.next(null);
          }
        })
      )
      .subscribe();
  }

  pusherUnSub() {
    this.pusherService.channel.unsubscribe();
  }
}
