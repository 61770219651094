import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { ProvidersDataModel, ProvidersModel } from '@core/services';
import { map, Observable } from 'rxjs';

export const providersData: ProvidersModel[] = [
  {
    id: '01HA6PXWX0F92PQTD7RKK9CG4M',
    name: 'Chat GPT 3.5',
    code: 'gpt35',
    description: 'An advanced chatbot provider',
    icon: 'chat-icon1.png'
  },
  {
    id: '01HA6PXWX0F92PQTD7RKK9CG45',
    name: 'AI Chat Service',
    code: 'aichat',
    description: 'A smart AI chat service for businesses',
    icon: 'chat-icon2.png'
  },
  {
    id: '01HA6PXWX0F92PQTD7RKK9CG46',
    name: 'Virtual Assistant Pro',
    code: 'vapro',
    description: 'Your virtual assistant for daily tasks',
    icon: 'chat-icon3.png'
  },
  {
    id: '01HA6PXWX0F92PQTD7RKK9CG47',
    name: 'TalkBot 3000',
    code: 'talkbot3k',
    description: 'The future of human-AI communication',
    icon: 'chat-icon4.png'
  }
];

@Injectable({
  providedIn: 'root'
})
export class ProvidersService {
  private readonly _apiUrl = environment.apiBaseUrl;
  constructor(private _http: HttpClient) {}
  getProviders(): Observable<ProvidersModel[]> {
    return this._http.get<ProvidersDataModel>(`${this._apiUrl}/providers`).pipe(map(() => providersData));
  }
}
