import { ULIDType } from 'jplus-design-system-angular';
export interface TemplatesMetadataModel {
  rulesCount: number;
  toneOfVoiceCount: number;
}

export interface TemplatesModel {
  id: ULIDType;
  clientId: string;
  name: string;
  prompt: string;
  rules: string[];
  toneOfVoice: string[];
  metadata: TemplatesMetadataModel;
  briefingFields: string[];
}

export interface TemplateDataModel {
  data: TemplatesModel[];
}

export type CreateTemplatePayload = Omit<TemplatesModel, 'id' | 'metadata' | 'clientId'>;
