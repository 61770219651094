import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { ModelsData, ModelsDataItem } from './';
import { map, Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ClientsService } from '../clients';

function mapData(models: ModelsData): ModelsDataItem[] {
  return models?.data || [];
}

@Injectable({
  providedIn: 'root'
})
export class ModelsService extends CommonService {
  constructor(
    protected override _http: HttpClient,
    private _clientService: ClientsService
  ) {
    super(_http);
  }

  getModels(): Observable<ModelsDataItem[]> {
    return this._http.get<ModelsData>(`${this._apiUrl}/models`).pipe(map(mapData), shareReplay(1));
  }

  getModelById(): Observable<ModelsDataItem[]> {
    return this._clientService.getDataByClientId<ModelsData, ModelsDataItem>(`${this._apiUrl}/clients/{id}/models`);
  }
}
