export interface ProvidersDataModel {
  data: ProvidersModel[];
}

export interface ProvidersModel {
  id: string;
  name: string;
  code: string;
  description: string;
  icon: string;
}
