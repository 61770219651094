export enum PusherBatchPageTypes {
  CREATED = 'batch.created',
  UPDATED = 'batch.updated',
  DELETED = 'batch.deleted'
}


export interface PusherBatchesData {
  batchId: string
  metadata: PusherBatchesMetadata
}

export interface PusherBatchesMetadata {
  deliverablesCount: number
  jobsCount: number
}
