import { Injectable, signal } from '@angular/core';
import {
  ClientChannelEvents,
  ClientCreateModel,
  ClientDataModel,
  ClientDataRules,
  ClientDifysDataModel,
  ClientDifysModel,
  ClientRulesModel
} from './';
import { BehaviorSubject, catchError, map, Observable, of, switchMap } from 'rxjs';
import { CommonService } from '../common.service';
import { ClientListModel, ULIDType } from 'jplus-design-system-angular';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';

export const CLIENT_STORAGE_KEY = 'currentClient';

function assertNever(value: string): never {
  throw new Error(`Unexpected value: ${value}`);
}

const idRegexp = /{id}/gi;

interface DataModel {
  data: unknown[];
}

export const updateClientValue = (
  clientList: BehaviorSubject<ClientListModel[]>,
  value: ClientListModel,
  status: (typeof ClientChannelEvents)[keyof typeof ClientChannelEvents]
) => {
  const currentClientList = clientList.value.slice(); // Make a copy of the current list
  const targetIndex = currentClientList.findIndex(item => item.id === value.id);

  switch (status) {
    case ClientChannelEvents.Updated:
      if (targetIndex >= 0) {
        currentClientList[targetIndex] = value;
      }
      break;
    case ClientChannelEvents.Created:
      if (targetIndex === -1) {
        currentClientList.unshift(value);
      }
      break;
    case ClientChannelEvents.Deleted:
      if (targetIndex >= 0) {
        currentClientList.splice(targetIndex, 1);
      }
      break;
    default:
      assertNever(status);
  }

  clientList.next(currentClientList);
};

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends CommonService {
  currentClient = new BehaviorSubject<ClientListModel>({} as ClientListModel);
  clientListSubject = new BehaviorSubject<ClientListModel[]>([]);
  clientListSignal = toSignal(this.clientListSubject.asObservable(), { initialValue: [] });
  isLoadingResults = signal(true);

  getClientById(id: string): Observable<ClientListModel> {
    return this._http.get<ClientListModel>(`${this._apiUrl}/clients/${id}`);
  }

  clientList(): Observable<ClientListModel[]> {
    return this._http.get<ClientDataModel>(`${this._apiUrl}/clients`).pipe(map(clients => clients?.data || []));
  }

  create(payload: ClientCreateModel): Observable<ClientListModel> {
    return this._http.post<ClientListModel>(`${this._apiUrl}/clients`, payload);
  }

  delete(id: string): Observable<null> {
    return this._http.delete<null>(`${this._apiUrl}/clients/${id}`, {});
  }

  update(id: string, payload: ClientCreateModel): Observable<ClientListModel> {
    return this._http.put<ClientListModel>(`${this._apiUrl}/clients/${id}`, payload);
  }

  deleteClientFromSubject(clientId: ULIDType) {
    updateClientValue(this.clientListSubject, { id: clientId } as ClientListModel, ClientChannelEvents.Deleted);

    const index = this.clientListSubject.value.findIndex(client => client.id === this.currentClient.value.id);

    if (index === -1) {
      this.clientStorageUpdate(this.clientListSubject?.value[0] ?? {});
    }

    this.isLoadingResults.set(false);
  }

  getDataByClientId<T extends DataModel, R>(url: string): Observable<R[]> {
    return this.currentClient.pipe(
      switchMap(client => {
        if (client?.id) {
          const updateUrl = url.replace(idRegexp, client.id);
          return this._http.get<T>(updateUrl).pipe(map(res => res?.data || [])) as Observable<R[]>;
        }
        return of([]);
      }),
      catchError(() => of([]))
    );
  }

  getClientRules(): Observable<ClientRulesModel[]> {
    return this.getDataByClientId<ClientDataRules, ClientRulesModel>(`${this._apiUrl}/clients/{id}/rules`);
  }

  getClientToneOfVoice(): Observable<ClientRulesModel[]> {
    return this.getDataByClientId<ClientDataRules, ClientRulesModel>(`${this._apiUrl}/clients/{id}/tone-of-voice`);
  }

  clientStorageUpdate(client: ClientListModel) {
    localStorage.setItem(CLIENT_STORAGE_KEY, JSON.stringify(client));
    this.currentClient.next(client);
  }

  activeClient(savedClient: ClientListModel, slug = '') {
    return this.clientList().pipe(
      takeUntilDestroyed(),
      map(clients => {
        let client!: ClientListModel;
        if (slug) {
          client = clients.find(cls => cls.slug === slug) || savedClient;
        } else {
          client = Object.keys(savedClient).length > 0 ? savedClient : clients[0] || {};
        }
        this.clientStorageUpdate(client);
        this.currentClient.next(client);
        this.clientListSubject.next(clients);
        this.isLoadingResults.set(false);
        return clients;
      })
    );
  }

  getDifys(): Observable<ClientDifysModel[]> {
    return this.getDataByClientId<ClientDifysDataModel, ClientDifysModel>(`${this._apiUrl}/clients/{id}/difys`);
  }
}
