import { StatusTypes } from './status.model';

export enum PusherJobsPageTypes {
  CREATED = 'job.created',
  UPDATED = 'job.updated',
  DELETED = 'job.deleted'
}


export interface PusherJobsData {
  jobId: string
  batchId: string
  status: keyof typeof StatusTypes
  metadata: PusherJobsMetadata
  name?: string;
}


export interface PusherJobsMetadata {
  deliverablesCount: number
}
