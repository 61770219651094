import { ULIDType } from 'jplus-design-system-angular';

export interface BatchList {
  data: Batch[];
}

export interface Batch {
  id: ULIDType;
  clientId: string;
  name: string;
  contentType: string;
  languages: LanguagesModel;
  callbackUrl: string;
  principal: Principal;
  fields: Field[];
  deliverables: unknown[];
  internalId: number;
  fileId: ULIDType;
  metadata: Metadata;
  status: string;
  categories: BatchCategories[];
}

export interface LanguagesModel {
  source: string | null;
  target: string | null;
}

export interface BatchCategories {
  name: string;
  recordsCount: number;
}

export interface Principal {
  user: User;
  applicationId: string;
}

export interface User {
  email: string;
}

export interface Field {
  batchId: string;
  id: string;
  name: string;
  outputFormat: string;
  internalId: number;
}

export interface Metadata {
  briefFields: string[];
  deliverablesCount: number;
  jobsCount: number;
  taskFieldsCount: number;
}

export type BatchPusherData = {
  batchId: ULIDType;
};

export type BatchPusherMetadata = {
  metadata: {
    deliverablesCount: number;
    jobsCount: number;
  };
};

export { LanguagesModel as LanguagesMetadata }
