import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthModule } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular-ivy';

import { environment } from '@environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IdTokenHttpInterceptor } from '@core/interceptors';

import { AppComponent } from './app.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/], // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, HttpClientModule, AuthModule.forRoot(environment.authConfig), NgIdleKeepaliveModule.forRoot()],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdTokenHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
