import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { Channel } from 'pusher-js';
import { WebSocketService } from './pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  channel: Channel = {} as Channel;
  pusherBind = new Subject<string | null>();
  webSocketService: WebSocketService = inject(WebSocketService);
}
