import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { HttpBackend, HttpClient } from '@angular/common/http';
import {
  Batch,
  BatchList,
  BatchUpload,
  BatchUploadRequestData,
  Deliverables,
  DeliverablesList,
  FileDataModel,
  Job,
  JobDeliverablesPatchRequestModel,
  JobsList,
  StatusTypes
} from '@pages/batches/models';
import { environment } from '@environments/environment';
import { ClientsService } from './clients';
import { ULIDType } from 'jplus-design-system-angular';
import { CreateJobPayload } from '@pages/batches';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private readonly _apiUrl = environment.apiBaseUrl;

  constructor(
    private _http: HttpClient,
    private _handler: HttpBackend,
    private _clientService: ClientsService
  ) {}

  // BATCHES

  getBatchData(): Observable<BatchList> {
    return this._clientService.currentClient.pipe(
      switchMap(client => (client.id ? this._http.get<BatchList>(`${this._apiUrl}/clients/${client.id}/batches`) : EMPTY))
    );
  }

  addBatchDataById(client: ULIDType, batch: ULIDType): Observable<Batch> {
    return this._http.get<Batch>(`${this._apiUrl}/clients/${client}/batches/${batch}`);
  }

  getBatchDataById(batch: ULIDType): Observable<Batch> {
    return this._clientService.currentClient.pipe(
      switchMap(client => (client.id ? this._http.get<Batch>(`${this._apiUrl}/clients/${client.id}/batches/${batch}`) : EMPTY))
    );
  }

  deleteBatchData(batchId: ULIDType) {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.delete(`${this._apiUrl}/clients/${client.id}/batches/${batchId}`))
    );
  }

  createBatchFileRequestData(data: BatchUpload): Observable<BatchUploadRequestData> {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.post<BatchUploadRequestData>(`${this._apiUrl}/clients/${client.id}/files`, data))
    );
  }

  updateBatchFileData(url: string, file: File) {
    const http: HttpClient = new HttpClient(this._handler); // <= Removes Auth Interceptor Auth Headers

    return http.put(url, file);
  }

  downloadBatchData(batchId: ULIDType): Observable<FileDataModel> {
    return this._clientService.currentClient.pipe(
      switchMap(client => this._http.post<FileDataModel>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/download`, null))
    );
  }

  // JOBS

  getJobsData(batchId: ULIDType): Observable<JobsList> {
    return this._clientService.currentClient.pipe(
      switchMap(client => (client.id ? this._http.get<JobsList>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs`) : EMPTY))
    );
  }

  getJobDataById(batchId: ULIDType, jobId: ULIDType): Observable<Job> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        client.id ? this._http.get<Job>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}`) : EMPTY
      )
    );
  }

  deleteJob(batchId: ULIDType, jobId: ULIDType) {
    return this._clientService.currentClient.pipe(
      switchMap(client => (client?.id ? this._http.delete(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}`) : EMPTY))
    );
  }

  downloadJobData(batchId: ULIDType, jobId: ULIDType): Observable<FileDataModel> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        this._http.post<FileDataModel>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/download`, null)
      )
    );
  }

  // DELIVERABLES

  getDeliverablesData(batchId: ULIDType, jobId: ULIDType): Observable<DeliverablesList> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        client.id
          ? this._http.get<DeliverablesList>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/deliverables`)
          : EMPTY
      )
    );
  }

  getDeliverablesById(batchId: ULIDType, jobId: ULIDType, deliverableId: ULIDType): Observable<Deliverables> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        client.id
          ? this._http.get<Deliverables>(
              `${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/deliverables/${deliverableId}`
            )
          : EMPTY
      )
    );
  }

  downloadDeliverableData(batchId: ULIDType, jobId: ULIDType): Observable<FileDataModel> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        this._http.post<FileDataModel>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/deliverables/download`, null)
      )
    );
  }

  createDraftJob(batchId: ULIDType, payload: Partial<CreateJobPayload>): Observable<Job> {
    return this._clientService.currentClient.pipe(
      switchMap(client => {
        if (client?.id) {
          return this._http.post<Job>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs`, payload);
        }
        return of({} as Job);
      })
    );
  }

  editDraftJob(batchId: ULIDType, jobId: ULIDType, payload: Partial<CreateJobPayload>): Observable<Job> {
    return this._clientService.currentClient.pipe(
      switchMap(client => {
        if (client?.id) {
          return this._http.put<Job>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}`, payload);
        }
        return of({} as Job);
      })
    );
  }

  updateJobStatus(batchId: ULIDType, jobId: ULIDType, status: keyof typeof StatusTypes = 'ACCEPTED'): Observable<Job> {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        client.id
          ? this._http.put<Job>(`${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/status`, {
              status
            })
          : EMPTY
      )
    );
  }

  updateJobDeliverableVersion(batchId: ULIDType, jobId: ULIDType, deliverableId: ULIDType, fieldId: ULIDType, version: ULIDType) {
    return this._clientService.currentClient.pipe(
      switchMap(client =>
        client.id
          ? this._http.patch(
              `${this._apiUrl}/clients/${client.id}/batches/${batchId}/jobs/${jobId}/deliverables/${deliverableId}/fields/${fieldId}`,
              {
                version
              }
            )
          : EMPTY
      )
    );
  }

  regenerateDeliverables<T>(batch: ULIDType, job: ULIDType, payload: JobDeliverablesPatchRequestModel<T>): Observable<null> {
    return this._clientService.currentClient.pipe(
      switchMap(client => {
        if (client.id) {
          return this._http.patch<null>(`${this._apiUrl}/clients/${client.id}/batches/${batch}/jobs/${job}/deliverables`, payload);
        }
        return EMPTY;
      })
    );
  }
}
