export const APP_ROUTES = {
  batches: 'batches',
  jobs: 'jobs',
  templates: 'templates',
  strategyAI: 'chat',
  settings: 'settings',
  client: 'client',
  clients: 'clients',
  components: 'components',
  login: 'login',
  accessDenied: 'access-denied',
  authCallback: 'auth-callback',
  reportingChats: 'reporting/chats',

  get clientBatches(): string {
    return `:${this.client}/${this.batches}`;
  },

  get clientJobs(): string {
    return `:${this.client}/${this.jobs}`;
  },

  get clientTemplates(): string {
    return `:${this.client}/${this.templates}`;
  }
};
