import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { mergeMap, Observable, take } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class IdTokenHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    return this.authService.idTokenClaims$.pipe(
      take(1),
      mergeMap(idToken =>  {
        if (idToken?.__raw && !request.url.includes('geolocation-db.com')) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${idToken.__raw}`
            }
          });
        }
        return next.handle(request);
      })
    );
  }
}
