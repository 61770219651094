import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { map, Observable } from 'rxjs';

export const authGuard: CanActivateFn = (_, state): Observable<boolean> => {
  const router = inject(Router);
  const auth = inject(AuthService);
  return auth.isAuthenticated$.pipe(
    map(isAuthenticated => {
      if (isAuthenticated) {
        return true;
      }

      router.navigate(['/login'], { queryParams: { returnUrl: state?.url ?? '' } });
      return false;
    })
  );
};
