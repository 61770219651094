import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'jplus-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private readonly _iconRegistry: MatIconRegistry) {
    const defaultFontSetClasses = this._iconRegistry.getDefaultFontSetClass();
    const outlinedFontSetClasses = defaultFontSetClasses
      .filter(fontSetClass => fontSetClass !== 'material-icons')
      .concat(['material-symbols-outlined']);
    this._iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
  }
}
