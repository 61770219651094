import { Subject } from 'rxjs';

export class PusherServiceStub {
  channel = {
    subscribe() {},
    unsubscribe() {},
    bind() {},
    unbind() {}
  }; // eslint-disable-line

  pusherBind = new Subject<string | null>(); // eslint-disable-line
}
