export enum PusherDeliverablesPageTypes {
  CREATED = 'deliverable.created',
  UPDATED = 'deliverable.updated',
  DELETED = 'deliverable.deleted'
}

export interface PusherDeliverablesData {
  jobId: string
  deliverableId: string
  status: string
  fields: PusherDeliverablesField[]
}

export interface PusherDeliverablesField {
  id: string
  name: string
  status: string
  content: string
  metadata: PusherDeliverablesMetadata
}

export interface PusherDeliverablesMetadata {
  wordsCount: number
  charactersCount: number
}
