interface Mapping {
  id: string[];
  name: string[][];
  details: {
    [key: string]: string[];
  };
}

interface RateLimits {
  requestsPerDay: number;
  requestsPerMinute: number;
  tokensPerMinutes: number;
}

interface LogitBias {
  key: number;
}

interface Settings {
  temperature: number;
  topP: number;
  n: number;
  stream: boolean;
  stop: string;
  maxTokens: number;
  presencePenalty: number;
  frequencyPenalty: number;
  logitBias: LogitBias;
}

export interface ModelsDataItem {
  id: string;
  providerId: string;
  inputCategory: null | string;
  category: string;
  name: string;
  code: string;
  description: string;
  parentId: null | string;
  mapping: Mapping;
  rateLimits: RateLimits;
  maxTokens: number;
  settings: Settings;
}

export interface ModelsData {
  data: ModelsDataItem[];
}
