import { ULIDType } from 'jplus-design-system-angular';
import { StatusTypes } from '@pages/batches/models/status.model';

export interface DeliverablesList {
  data: Deliverables[];
}

export interface Deliverables {
  id: ULIDType;
  jobId: ULIDType;
  internalId: number;
  fields: DeliverablesField[];
  metadata: DeliverablesMetadata;
  status: string;
  displayName: string;
  displayId: string;
  sources: DeliverableSources[];
  zoomImageLink: string;
}

export interface DeliverableSettings {
  minWords: null | number;
  maxWords: null | number;
  minChars: null | number;
  maxChars: null | number;
}

export interface DeliverableSources {
  content: string;
  id: ULIDType;
  internalId: number;
}

export interface DeliverablesField {
  id: ULIDType;
  jobId: ULIDType;
  name: string;
  internalId: number;
  content: string;
  metadata: DeliverablesFieldMetadata;
  status: string;
  version: number;
  versionId: ULIDType;
  versionSet: number;
  versionActive: number;
  versions: VersionsField[];
  settings: DeliverableSettings;
}

export interface VersionsField {
  id: ULIDType;
  content: string;
  metadata: DeliverablesFieldMetadata;
  version: number;
}

export interface DeliverablesFieldMetadata {
  wordsCount: number;
  charactersCount: number;
  tokensCount: number;
  executionTime: number;
}

export interface DeliverablesMetadata {
  fieldsCount: number;
}

interface JobDeliverableFieldPartialRequestModel {
  id: ULIDType;
  status?: string;
  instruction?: string;
}

export interface JobDeliverablePartialRequestModel {
  id: ULIDType;
  fields: Partial<JobDeliverableFieldPartialRequestModel[]>;
}

export interface JobDeliverablesPatchRequestModel<T> {
  deliverables: T[];
}

export interface JobPlpDeliverablePartialRequestModel extends JobDeliverablePartialRequestModel {
  status: keyof typeof StatusTypes;
}

