import { Injectable } from '@angular/core';
import { CommonService } from '../common.service';
import { ChatMenu, ChatMessages, CreateChatResponse, ULIDType } from 'jplus-design-system-angular';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends CommonService {
  getChats(): Observable<ChatMenu[]> {
    return this._http.get<{ data: ChatMenu[] }>(`${this._apiUrl}/chats`).pipe(map(res => res?.data || []));
  }

  getChatById(id: string): Observable<ChatMenu> {
    return this._http.get<ChatMenu>(`${this._apiUrl}/chats/${id}`);
  }

  createChat(): Observable<CreateChatResponse> {
    return this._http.post<CreateChatResponse>(`${this._apiUrl}/chats`, {});
  }

  addChatMessage(chatId: ULIDType, message: string): Observable<ChatMessages> {
    return this._http.post<ChatMessages>(`${this._apiUrl}/chats/${chatId}/messages`, { message });
  }
}
