import { Directive, inject, OnDestroy } from '@angular/core';
import { ULIDType } from 'jplus-design-system-angular';
import { ClientsService, WebSocketService } from '@core/services';
import { Channel } from 'pusher-js';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive()
export abstract class PusherAbstract implements OnDestroy {
  channelName = (id: ULIDType): string => `client.${id}.jobs`;
  clientService = inject(ClientsService);
  pusherService = inject(WebSocketService);
  channelData = new Set<{ id: string; channel: string }>();
  channel!: Channel;
  pusherBind = new Subject<null>();
  debounceTime = 500;

  get slug(): string {
    return this.clientService.currentClient.value?.slug || '';
  }

  init() {
    this.clientService.currentClient
      .pipe(
        debounceTime(this.debounceTime),
        takeUntilDestroyed(),
        distinctUntilChanged((previous, current) => previous.id === current.id),
        map(client => {
          if (client.id) {
            if ([...this.channelData].length > 0) {
              const toUnsubscribe = [...this.channelData].filter(({ id }) => id !== client.id);
              if (toUnsubscribe?.length > 0) {
                toUnsubscribe.forEach(item => {
                  this.pusherService.unsubscribe([item.channel]);
                  this.channelData.delete(item);
                });
              }
            }

            const name = this.channelName(client.id);
            this.channelData.add({ id: client.id, channel: name });
            this.channel = this.pusherService.pusher.subscribe(name);
            this.pusherBind.next(null);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.pusherService.unsubscribe([...this.channelData].map(({ channel }) => channel));
  }
}
