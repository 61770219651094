import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const ChatBotAnimationProps = [
  transition('void => *', [
    style({ visibility: 'hidden', opacity: 0.4, transform: 'translateY(50px)' }),
    animate(`.3s ease-in-out`, style({ visibility: 'visible', opacity: 1, transform: 'translateY(0)' }))
  ]),
  transition('* => void', [
    style({ visibility: 'visible', opacity: 1, transform: 'translateY(0)' }),
    animate(`.15s ease-in-out`, style({ visibility: 'hidden', opacity: .4, transform: 'translateY(50px)' }))
  ])
];

export const ChatBotAnimation: { readonly ChatBotAnimationContainer: AnimationTriggerMetadata } = {
  ChatBotAnimationContainer: trigger('chatBotAnimation', ChatBotAnimationProps)
};
