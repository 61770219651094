import { ULIDType } from 'jplus-design-system-angular';
import { LanguagesModel, Metadata, Principal } from '@pages/batches/models';

export type MetadataDeliverablesCount = Pick<Metadata, 'deliverablesCount'>

export const PLP_MODEL_ATTRIBUTE = 'CATEGORY_DESCRIPTION';

export interface JobsList {
  data: Job[];
}

export interface Job {
  id: ULIDType;
  batchId: ULIDType;
  name: string;
  dify: {id: ULIDType; name: string};
  model: JobModel;
  template: string;
  promptConfiguration: JobPromptConfiguration;
  fieldMapping: JobFieldMapping;
  status: string;
  metadata: MetadataDeliverablesCount;
  principal: Principal;
  batch: JobBatchData;
  category?: string | null;
}

export interface JobBatchData {
  id: ULIDType;
  name: string;
  language: LanguagesModel;
}

export interface JobModel {
  id: ULIDType;
  providerId: string;
  category: string;
  name: string;
  code: string;
  description: string;
  parentId: ULIDType | null;
  mapping: JobMapping;
  rateLimits: JobRateLimits;
  maxTokens: number;
  settings: JobSettings;
  inputCategory: string;
}

export interface JobMapping {
  id: ULIDType[];
  name: string[];
  details: JobDetails;
}

export interface JobDetails {
  id: ULIDType;
}

export interface JobRateLimits {
  requestsPerDay: number;
  requestsPerMinute: number;
  tokensPerMinutes: number;
}

export interface JobSettings {
  temperature: number;
  topP: number;
  n: number;
  stream: boolean;
  stop: number;
  maxTokens: number;
  presencePenalty: number;
  frequencyPenalty: number;
  logitBias: number;
}

export interface JobPromptConfiguration {
  instruction: string;
  rules: string[];
  toneOfVoice: string[];
  briefingFields: string[];
}

export interface JobFieldMapping {
  id: ULIDType[];
  name: string[];
  details: JobDesignDetails;
}

export interface JobDesignDetails {
  fit: string[];
  colour: string[];
  design: string[];
}
