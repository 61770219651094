import { ClientListModel, ULIDType } from 'jplus-design-system-angular';

export interface ApiResponse<T> {
  data: T[];
}

export interface ClientDataModel extends ApiResponse<ClientListModel> {}

export interface ClientCreateModel extends Omit<ClientListModel, 'id' | 'metadata' | 'show'> {}

export const CLIENT_CHANNEL = 'clients';

export const ClientChannelEvents = {
  Created: 'client.created',
  Updated: 'client.updated',
  Deleted: 'client.deleted'
} as const;

export interface PusherClientModel {
  clientId: ULIDType;
}

export interface ClientDataRules extends ApiResponse<ClientRulesModel> {}

export interface ClientRulesModel {
  id: ULIDType;
  clientId: ULIDType;
  value: string;
}

export interface ClientDifysDataModel extends ApiResponse<ClientDifysModel> {}
export interface ClientDifysModel {
  description: string;
  id: ULIDType;
  name: string;
  workflowId: ULIDType;
}
